<template>
  <template v-if="this.$uiProviderCompany == this.$syncfusionCompanyKey">
    <SetLoader v-if="!syncGridShow" />
    <!-- <SyncfusionGrid ref="grid" :gridId="gridId" /> -->
  </template>
  <template v-else-if="this.$uiProviderCompany == this.$devExpressCompanyKey">
    <DevExpressGrid v-if="dxGridShow" ref="grid" :gridId="gridId" />
  </template>
</template>
<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: "LIST",
  props: ["key", "filterId"],
  components: {},
  data() {
    return {
      gridId: this.$listPageGridId,
      dxGridShow: true,
      syncGridShow: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.isMembershipPages) {
      next();
      return;
    }

    setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
    next();
  },
  watch: {
    "$route.params.key"() {
      if (this.$route.name === "list") {
        setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
        this.dxGridShow = false;
        setTimeout(() => {
          this.dxGridShow = true;
        }, 1);
      }
    },
    "$route.query.filterId"() {
      if (this.$route.name === "list") {
        setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
        this.dxGridShow = false;
        setTimeout(() => {
          this.dxGridShow = true;
        }, 1);
      }
    },
  },
  mounted() {
    setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);

    if (this.$uiProviderCompany == this.$syncfusionCompanyKey) {
      this.syncGridShow = false;
      this.$refs.grid.dataStateChange({ skip: 1, take: 50 });
    }
  },
};
</script>
